/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import {useStaticQuery, graphql} from "gatsby"
import Visual from "../svg/dePretis_Keyvisual_weiss.svg"

import Header from "./header"
import Footer from "./footer"

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

    return (
        <>
            <div className="container overflow-x-hidden">
                <div className="grid grid-cols-12 gap-7">
                    <div className="col-span-12 lg:col-span-10">
                        <Header siteTitle={data.site.siteMetadata?.title || `Title`}/>
                        <main>{children}</main>
                        <Footer />
                    </div>
                    <div className="col-span-2 hidden lg:block py-7">
                        <div className="visual fixed right-auto top-7 text-right right-auto lg:ml-4 xl:ml-6">
                            <Visual className="fill-current" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
