import {Link} from "gatsby"
import React from "react"

const Footer = ({siteTitle}) => (
    <footer className="mt-24 mb-7 font-nunito text-sm">
        <p>
            <span className="mr-2 text-gray">© {new Date().getFullYear()}. de Pretis GmbH</span><br
            className="block md:hidden"/>
            <Link className="text-current hover:text-opacity-70 transition duration-300"
                  to="/impressum/">Impressum / Datenschutz</Link>
        </p>
    </footer>
)

export default Footer
