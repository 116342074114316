import {Link} from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "../svg/dePretis_Logo_weiss.svg"

const Header = ({siteTitle}) => (
    <header className="mt-10">
        <div className="grid grid-cols-12 gap-7">
            <div className="col-span-12 md:col-span-10">
                <h1 className="logo w-40 md:w-50">
                    <span className="sr-only">{siteTitle}</span>
                    <Link to="/">
                        <Logo className="fill-current" />
                    </Link>
                </h1>
            </div>
        </div>
    </header>
)

Header.propTypes = {
    siteTitle: PropTypes.string,
}

Header.defaultProps = {
    siteTitle: `dePretis`,
}

export default Header
